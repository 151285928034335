import sgImgS1 from '../images/service-single/graphics/img-1.jpg'
import sgImgS2 from '../images/service-single/graphics/img-2.jpg'
import sgImgS3 from '../images/service-single/graphics/img-3.jpg'

import brImgS1 from '../images/service-single/brand/img-1.jpg'
import brImgS2 from '../images/service-single/brand/img-2.jpg'
import brImgS3 from '../images/service-single/brand/img-3.jpg'

import uxImgS1 from '../images/service-single/ux/img-1.jpg'
import uxImgS2 from '../images/service-single/ux/img-2.jpg'
import uxImgS3 from '../images/service-single/ux/img-3.jpg'

import dvImgS1 from '../images/service-single/development/img-1.jpg'
import dvImgS2 from '../images/service-single/development/img-2.jpg'
import dvImgS3 from '../images/service-single/development/img-3.jpg'

import adImgS1 from '../images/service-single/app/img-1.jpg'
import adImgS2 from '../images/service-single/app/img-2.jpg'
import adImgS3 from '../images/service-single/app/img-3.jpg'

import swImgS1 from '../images/service-single/software/img-1.jpg'
import swImgS2 from '../images/service-single/software/img-2.jpg'
import swImgS3 from '../images/service-single/software/img-3.jpg'

import smImgS1 from '../images/service-single/sm/img-1.jpg'
import smImgS2 from '../images/service-single/sm/img-2.jpg'
import smImgS3 from '../images/service-single/sm/img-3.jpg'

import dmImgS1 from '../images/service-single/dm/img-1.jpg'
import dmImgS2 from '../images/service-single/dm/img-2.jpg'
import dmImgS3 from '../images/service-single/dm/img-3.jpg'

import emailImgS1 from '../images/service-single/email/img-1.jpg'
import emailImgS2 from '../images/service-single/email/img-2.jpg'
import emailImgS3 from '../images/service-single/email/img-3.jpg'



const Services = [
    {
        Id: '1',
        sImgS: dvImgS1,
        sTitle: 'Full-Stack Development',
        description:
            `
            Mastering both front-end elegance and back-end robustness, 
            I specialize in delivering end-to-end web applications. 
            Seamlessly integrating technologies like HTML, CSS, and JavaScript with powerful server-side solutions, 
            I bring a comprehensive approach to enhance your digital presence.
            `,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-coding',
        ssImg1: dvImgS2,
        ssImg2: dvImgS3,
    },
    {
        Id: '2',
        sImgS: adImgS1,
        sTitle: 'AI Engineering',
        description:
            `
            Experience the forefront of innovation with my specialized AI engineering services. 
            Leveraging advanced machine learning algorithms and frameworks like PyTorch, 
            I architect intelligent systems. From nuanced natural language processing to intricate computer vision applications, 
            I bring a strategic approach to implementing AI solutions that translate data into actionable insights.
            `,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-software-developer',
        ssImg1: adImgS2,
        ssImg2: adImgS3,
    },
    {
        Id: '3',
        sImgS: swImgS1,
        sTitle: 'Software Development',
        description:
            `
            Embark on technological excellence with my bespoke software development services. 
            As a seasoned software engineer, I provide tailored solutions addressing unique business challenges. 
            From conceptualization to execution, I ensure scalable and efficient software applications aligned with your organizational goals.
            `,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-app-development',
        ssImg1: swImgS2,
        ssImg2: swImgS3,
    }
]

export default Services;